<!-- (click)="file.click()" -->
<div class="dropzone dropzone-light upload-btn-wrapper w-100 mb-0">
  <button type="button" class="btn-upload-border d-block w-100"><i class="fa-light fa-cloud-arrow-up"></i>Browse or Drop it here</button>
  <input type="file" [multiple]="MaxFileLength>1?true:false" style="display: block;" [accept]="FileFilter"
    (change)="HandleFileInput($event)" #file />
  <div class="dz-default dz-message">
    <span><i class="ti-cloud-up"></i></span>
  </div>

</div>

<div class="d-flex flex-wrap align-items-center upload-content-list" *ngIf="IsShowFiles">

  <ng-container *ngFor="let file of Files">
    <div [ngSwitch]="getFileType(file.Name)">
      <a *ngSwitchCase="'video'" href="javascript:void()"
        class="small-media-items small-media-items-video position-relative mb-3">
        <div class="small-media-items-img"> <i class="bi bi-camera-video"></i> </div>
        <div class="small-media-items-action">
          <a href="javascript:void()" (click)="RemoveFile(file)" title="Delete"><i
              class="fa-light fa-trash-can"></i></a>
          <a href="javascript:void(0)" (click)="openFile(file)" target="_blank">
            <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
          </a>
        </div>
      </a>
      <a *ngSwitchCase="'image'" href="javascript:void()" class="small-media-items position-relative">
        <div class="small-media-items-img"> <img src="{{file.FileBase64}}" ViewImage
            [defaultSrc]="'assets/images/images/client/gulmohar/no-img.png.png'" /> </div>
        <div class="small-media-items-action">
          <a href="javascript:void()" (click)="RemoveFile(file)" title="Delete"><i
              class="fa-light fa-trash-can"></i></a>
          <a href="javascript:void(0)" (click)="openFile(file)" target="_blank">
            <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
          </a>
        </div>
      </a>


      <a *ngSwitchCase="'doc'" href="javascript:void()"
        class="upload-preview-ico">
        <div class="upload-preview-ico-icon">
          <i class="fa-light fa-file-excel"></i>
        </div>
        <div class="upload-preview-action">
          <a href="javascript:void(0)" (click)="openFile(file)" target="_blank">
            <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-regular fa-eye"></i>
          </a> 

          <a href="javascript:void()" (click)="RemoveFile(file)" title="Delete">
            <i class="fa-regular fa-xmark"></i>
          </a>

        </div>
      </a>


      <span *ngSwitchDefault>
        <div class="dz-filename">
          <i class="fa-regular fa-image"></i> <span data-dz-name="">{{file.Name}}</span>
        </div>
        <i (click)="openFile(file)" data-bs-toggle="tooltip" data-placement="bottom" title="View"
          class="fa-light fa-eye"></i>
        <i (click)="RemoveFile(file)" data-bs-toggle="tooltip" data-placement="bottom" title="Remove"
          class="fa-light fa-trash-can text-danger file-upload-details-remove"></i>
      </span>
    </div>
    <!-- <div class="dz-size"><span data-dz-size=""><strong>{{file.Size}}</strong> {{file.SizeSuffix}}</span></div> -->




  </ng-container>
</div>
