import { EditorConfig, Message } from './../../../../Shared/Helper/constants';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DropDownModel, FilterDropDownPostModel } from 'src/app/Shared/Helper/common-model';
import { DropDown_key } from 'src/app/Shared/Helper/constants';
import { FileInfo } from 'src/app/Shared/Helper/shared/file-selector/file-selector.component';
import { CommonService } from 'src/app/Shared/Services/common.service';
import { ProductImageViewModel, ProductMasterPostModel, ProductMasterViewModel, ProductStockModel } from 'src/app/Shared/Services/product.service';
import { ProductService } from '../../../../Shared/Services/product.service';
import { DropDownItem } from '../../../../Shared/Helper/common-model';
import { map, catchError } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { RecordTypeEnum } from 'src/app/Shared/Enum/fixed-value';
@Component({
  selector: 'app-product-add-edit',
  templateUrl: './product-add-edit.component.html',
  styleUrls: ['./product-add-edit.component.scss']
})
export class ProductAddEditComponent implements OnInit {
  @ViewChild('stockModelPopupClose') stockModelPopup!: ElementRef;
  dropDown = new DropDownModel();
  model = {} as ProductMasterPostModel;
  modelBk = {} as ProductMasterPostModel;
  isFileAttached = false;
  formgrp = this.fb.group({
    UniqueID: [undefined, {
      validators: [Validators.required,], asyncValidators: [this.checkSKUValidator.bind(this)], updateOn: 'blur'
    }],
    Name: [undefined, Validators.required],
    Price: [undefined, [Validators.required, this.minValueValidator.bind(this)]],
    SellingPrice: [undefined, this.minValueValidator.bind(this)],
    BtoBPrice: [undefined, [Validators.required, this.minBtoBValueValidator.bind(this)]],
    BtoBSellingPrice: [undefined, this.minBtoBValueValidator.bind(this)],
    ProductType: [undefined, Validators.required],
    Caption: [undefined,],
    ViewSection: [undefined,],
    Category: [undefined, Validators.required],
    Warehouse: [undefined],
    Brand: [undefined, Validators.required],
    SubCategory: [undefined],
    Occasion: [undefined],
    Fabric: [undefined],
    Length: [undefined],
    Color: [undefined],
    Pattern: [undefined],
    Summary: [undefined],
    Description: [undefined],
    Keyword: [undefined],
    MetaTitle: [undefined],
    MetaDesc: [undefined],
    ImagePath: [undefined, Validators.required],
    productFile: [undefined, undefined]

  });
  get ddlkeys() { return DropDown_key };
  get f() { return this.formgrp?.controls; }
  get getFileName() { return this.model.ImagePath ? this.model.ImagePath.split('/')[this.model.ImagePath.split('/')?.length - 1] : '' };
  editorConfig = EditorConfig.Config;
  productFile: any;
  ProductFiles = new Array<ProductImageViewModel>();
  stockModel = {} as ProductStockModel;
  stockFormGroup = this.fb.group({
    SizeId: [undefined, Validators.required],
    UnitPrice: [undefined, [Validators.required, this.minStockValueValidator.bind(this,)]],
    SellingPrice: [undefined, [Validators.required, this.minStockValueValidator.bind(this)]],
    Quantity: [undefined, Validators.required],
  });

  get sf() { return this.stockFormGroup?.controls; }
  tempStock: ProductStockModel | undefined;
  ddlAvailableProductSize: DropDownItem[] = [];
  get TotalFiles(): number {
    return this.ProductFiles?.length + (this.model.Files?.length ?? 0)
  }
  @Input() set Id(value: string) {
    this.model = {} as any;
    this.model.Id = value;
    if (this.model !== null && this.model.Id?.length > 0) {
      this.onGetDetail();
    } else {
      this.reset();
    }
  }

  @Output() OnSave = new EventEmitter<{ status: boolean, recordId: string }>();
  @Output() OnClose = new EventEmitter<boolean>();
  productTypeEnum = RecordTypeEnum;
  constructor(private readonly fb: FormBuilder, private _route: Router, private _activatedRoute: ActivatedRoute,
    public _commonService: CommonService, private readonly toast: ToastrService,
    private readonly _productService: ProductService) {
    this._activatedRoute.params.subscribe(x => {
      this.model.Id = this._activatedRoute.snapshot.params.id ? this._activatedRoute.snapshot.params.id : null;
      if (this.model.Id !== null) {
        this.onGetDetail();
      }
    });

  }
  data: any;
  minValueValidator(ctrl: AbstractControl): ValidationErrors | null {
    const val = this?.f != null && this?.f.SellingPrice?.value != undefined ? Number(this?.f?.SellingPrice?.value) : 0;
    const price = this?.f != null && this?.f.Price?.value != undefined ? Number(this?.f?.Price?.value) : 0;

    if (Number(price) < Number(val)) {
      return {
        minValue: true
      }
    }

    else if (Number(price) >= Number(val) && this.f?.Price?.invalid && this.f?.Price?.hasError("minValue")) {
      this.f?.Price.patchValue(price)
    }

    return null;
  }
  minBtoBValueValidator(ctrl: AbstractControl): ValidationErrors | null {
    const val = this?.f != null && this?.f.BtoBSellingPrice?.value != undefined ? Number(this?.f?.BtoBSellingPrice?.value) : 0;
    const price = this?.f != null && this?.f?.BtoBPrice?.value != undefined ? Number(this?.f?.BtoBPrice?.value) : 0;

    if (Number(price) < Number(val)) {
      return {
        minValue: true
      }
    }

    else if (Number(price) >= Number(val) && this?.f?.BtoBPrice?.invalid && this.f?.BtoBPrice?.hasError("minValue")) {
      this.f?.BtoBPrice.patchValue(price)
    }

    return null;
  }

  checkSellingPrice(isBtoB: boolean) {
    if (isBtoB) {
      const val = this?.f != null && this?.f.BtoBSellingPrice?.value != undefined ? Number(this?.f?.BtoBSellingPrice?.value) : 0;
      const price = this?.f != null && this?.f.BtoBPrice?.value != undefined ? Number(this?.f?.BtoBPrice?.value) : 0;

      if (val > price) {
        this.f.BtoBSellingPrice.setValue(price);
      }
      else if (price > 0 && val == 0) {
        this.f.BtoBSellingPrice.setValue(price);
      }
    } else {
      const val = this?.f != null && this?.f.SellingPrice?.value != undefined ? Number(this?.f?.SellingPrice?.value) : 0;
      const price = this?.f != null && this?.f.Price?.value != undefined ? Number(this?.f?.Price?.value) : 0;

      if (val > price) {
        this.f.SellingPrice.setValue(price);
      }
      else if (price > 0 && val == 0) {
        this.f.SellingPrice.setValue(price);
      }
    }

  }
  minStockValueValidator(ctrl: AbstractControl): ValidationErrors | null {
    const val = this?.sf != null && this?.sf.SellingPrice?.value != undefined ? Number(this?.sf?.SellingPrice?.value) : 0;
    const price = this?.sf != null && this?.sf?.UnitPrice?.value != undefined ? Number(this?.sf?.UnitPrice?.value) : 0;
    if (val > price) {
      this?.sf?.SellingPrice?.setValue(price);
      return null;
    }
    if (Number(price) < Number(val)) {
      return {
        minValue: true
      }
    }
    return null;
  }

  async checkSKUValidator(ctrl: AbstractControl): Promise<ValidationErrors | null> {
    if (!ctrl.value) {
      return null;
    }
    try {
      const response = await this._productService.checkSKUExist(ctrl.value, this.model.Id).toPromise();
      return response.IsSuccess && response.Data ? { duplicateSKU: true } : null;
    } catch (error) {
      return null;
    }
  }

  // checkSKUValidator1(ctrl: AbstractControl): Observable<ValidationErrors | null> {
  //   if (!ctrl.value) {
  //     return of(null);
  //   }

  //   return this._productService.checkSKUExist(ctrl.value, this.model.Id).pipe(
  //     map((response) => {
  //       return response.IsSuccess && response.Data ? { duplicateSKU: true } : null;
  //     }),
  //     catchError((error) => {
  //       return of(null);
  //     })
  //   );


  //   //  }
  // }

  checkStockSellingPrice() {
    const val = this?.sf != null && this?.sf.SellingPrice?.value != undefined ? Number(this?.sf?.SellingPrice?.value) : 0;
    const price = this?.sf != null && this?.sf.UnitPrice?.value != undefined ? Number(this?.sf?.UnitPrice?.value) : 0;
    if (val > price) {
      this?.sf?.SellingPrice?.setValue(price);
    } else if (price > 0 && val == 0) {
      this?.sf?.SellingPrice?.setValue(price);
    }
  }
  ngOnInit(): void {
    this.GetDropDown();
  }

  reset() {
    this.model = {} as ProductMasterPostModel;
    this.isFileAttached = false;
    this.productFile = undefined
    this.ProductFiles = [];
    this.stockModel = {} as ProductStockModel;
    this.formgrp.reset();
    this.stockFormGroup.reset()
  }
  ddlProductSize(): any {

    setTimeout(() => {
      //is bto b added may be the reason data binding issue of size ddl
      this.ddlAvailableProductSize = this.dropDown?.ddlProductSize?.filter(x => !(this.model?.Stocks?.filter(f => f.IsBtoB == this.stockModel.IsBtoB)?.map(y => { return y.SizeId }))?.includes(x.Value));
      // let filter = this.dropDown?.ddlProductSize?.filter(x => !(this.model.Stocks.map(y => { return y.SizeId })).includes(Number(x.Value)));
      return this.model?.Stocks?.length > 0 ? this.ddlAvailableProductSize : this.dropDown?.ddlProductSize;
    }, 10);

  }

  onSubmit() {


    this.formgrp.markAllAsTouched();
    if (this.formgrp.valid) {
      let isSellingPriceUpdate = false;
      let isUnitPriceUpdate = false;
      let isBtoBSellingPriceUpdate = false;
      let isBtoBUnitPriceUpdate = false;
      if (this.model?.Stocks?.length > 0) {
        this.model?.Stocks.forEach(element => {
          if (Number(element.UnitPrice) != Number(this.model.Price) && !element.IsBtoB) {
            isUnitPriceUpdate = true;
          }
          if (Number(element.SellingPrice) != Number(this.model.SellingPrice) && !element.IsBtoB) {
            isSellingPriceUpdate = true;
          }
          if (Number(element.UnitPrice) != Number(this.model.BtoBPrice) && element.IsBtoB) {
            isBtoBUnitPriceUpdate = true;
          }
          if (Number(element.SellingPrice) != Number(this.model.BtoBSellingPrice) && element.IsBtoB) {
            isBtoBSellingPriceUpdate = true;
          }
        });


      }

      if (isSellingPriceUpdate || isUnitPriceUpdate || isBtoBSellingPriceUpdate || isBtoBUnitPriceUpdate) {
        let strText = '';
        if (isSellingPriceUpdate) {
          strText += 'Selling Price';
        }
        if (isUnitPriceUpdate) {
          strText += strText.length > 0 ? ', Unit Price' : 'Unit Price';

        }
        if (isBtoBSellingPriceUpdate) {
          strText += strText.length > 0 ? ', B to B Selling Price' : 'B to B Selling Price';

        }
        if (isBtoBUnitPriceUpdate) {
          strText += strText.length > 0 ? ', B to B Unit Price' : 'B to B Unit Price';

        }

        this._commonService.Question(Message.AllowAutoUpdate.replace("#Text", ` ${strText} in Stock detail?`) as string).then(result => {
          if (result) {
            this.model.Stocks.forEach(x => {

              if (x.IsBtoB == false && isUnitPriceUpdate) {
                x.UnitPrice = this.model.Price as number;
              }
              if (x.IsBtoB == false && isSellingPriceUpdate) {
                x.SellingPrice = this.model.SellingPrice as number;
              }
              if (x.IsBtoB == true && isBtoBUnitPriceUpdate) {
                x.UnitPrice = this.model.BtoBPrice as number;
              }
              if (x.IsBtoB == true && isBtoBSellingPriceUpdate) {
                x.SellingPrice = this.model.BtoBSellingPrice as number;
              }
            });
          }
          setTimeout(() => {
            this.onSave();
          }, 100);
        })
      } else {
        this.onSave();
      }
    }


  }
  updatePriceField(value: any) {
    var ctrlPrice = this.formgrp.get('Price');
    var ctrlSellingPrice = this.formgrp.get('SellingPrice');
    var ctrlBtoBPrice = this.formgrp.get('BtoBPrice');
    var ctrlBtoBSellingPrice = this.formgrp.get('BtoBSellingPrice');
    switch (value) {
      case RecordTypeEnum.BtoC:
        ctrlPrice?.addValidators([Validators.required, this.minValueValidator.bind(this)]);
        ctrlSellingPrice?.addValidators([Validators.required]);
        ctrlBtoBPrice?.removeValidators([Validators.required]);
        ctrlBtoBSellingPrice?.removeValidators([Validators.required]);
        break;
      case RecordTypeEnum.BtoB:
        ctrlBtoBPrice?.addValidators([Validators.required, this.minBtoBValueValidator.bind(this)]);
        ctrlBtoBSellingPrice?.addValidators([Validators.required]);
        ctrlPrice?.removeValidators([Validators.required]);
        ctrlSellingPrice?.removeValidators([Validators.required]);
        break;

      default:
        ctrlPrice?.addValidators([Validators.required, this.minValueValidator.bind(this)]);
        ctrlSellingPrice?.addValidators([Validators.required]);
        ctrlBtoBPrice?.addValidators([Validators.required, this.minBtoBValueValidator.bind(this)]);
        ctrlBtoBSellingPrice?.addValidators([Validators.required]);
        break;

    }
    ctrlPrice?.updateValueAndValidity();
    ctrlSellingPrice?.updateValueAndValidity();
    ctrlBtoBPrice?.updateValueAndValidity();
    ctrlBtoBSellingPrice?.updateValueAndValidity();
  }

  private onSave() {


    if ((this.model?.Stocks == undefined || this.model?.Stocks?.length === 0) ||
      (this.model.Type == this.productTypeEnum.BtoB && this.model?.Stocks.filter(x => x.IsBtoB).length == 0) ||
      (this.model.Type == this.productTypeEnum.BtoC && this.model?.Stocks.filter(x => !x.IsBtoB).length == 0)) {
      this.toast.warning("Please add at least 1 stock item!", "Oops");
      return;
    } else if (this.model.Type == this.productTypeEnum.All && (this.model?.Stocks.filter(x => x.IsBtoB).length == 0 || this.model?.Stocks.filter(x => !x.IsBtoB).length == 0)) {
      let isBtoC = this.model?.Stocks.filter(x => !x.IsBtoB).length == 0;
      this.toast.warning(`Please add at least 1 stock item of ${isBtoC ? 'B2C' : 'B2B'}!`, "Oops");
      return;
    }
    this.model.Price = this.model.Price && this.model.Price > 0 ? Number(this.model.Price) : 0;
    this.model.SellingPrice = this.model.SellingPrice && this.model.SellingPrice > 0 ? Number(this.model.SellingPrice) : 0;
    this.model.BtoBPrice = this.model.BtoBPrice && this.model.BtoBPrice > 0 ? Number(this.model.BtoBPrice) : 0;
    this.model.BtoBSellingPrice = this.model.BtoBSellingPrice && this.model.BtoBSellingPrice > 0 ? Number(this.model.BtoBSellingPrice) : 0;


    this.model.Stocks.forEach(x => {
      x.Discount = x.Discount && x.Discount > 0 ? Number(x.Discount) : 0;
      x.UnitPrice = x.UnitPrice && x.UnitPrice > 0 ? Number(x.UnitPrice) : 0;
      x.SellingPrice = x.SellingPrice && x.SellingPrice > 0 ? Number(x.SellingPrice) : 0;
    })
    // this.model.ShippingCharge = this.model.ShippingCharge && this.model.ShippingCharge > 0 ? Number(this.model.ShippingCharge) : 0

    this._productService.AddUpdateProductMaster(this.model).subscribe(x => {
      if (x.IsSuccess) {
        this.toast.success("Product added successfully...", "Saved");
        this._route.navigate(['./admin/product']);
        this.OnSave.emit({ status: true, recordId: x.Data as string });
      } else {
        this.OnSave.emit({ status: false, recordId: x.Data as string });
        this.toast.error(x.Message as string, "Failed");
      }
    })


  }
  onGetDetail() {
    this._productService.GetProductMaster(this.model.Id)?.subscribe(response => {
      if (response.IsSuccess) {
        const data = response.Data as ProductMasterViewModel;
        this.model.Name = data.Name;
        this.model.ImagePath = data.ImagePath;
        this.model.Desc = data.Desc;
        this.model.Price = data.Price ? Number(data.Price) : undefined;
        this.model.SellingPrice = data.SellingPrice ? Number(data.SellingPrice) : data.Price ? Number(Object.assign(data.Price, 0)) : undefined;
        this.model.CategoryId = data.CategoryId ? data.CategoryId : undefined;
        this.model.SubCategoryId = data.SubCategoryId ? data.SubCategoryId : undefined;
        this.model.CaptionTagId = data.CaptionTagId ? data.CaptionTagId : undefined;
        this.model.Summary = data.Summary;
        this.model.Discount = data.Discount;
        this.model.BtoBPrice = data.BtoBPrice ? Number(data.BtoBPrice) : undefined;
        this.model.BtoBSellingPrice = data.BtoBSellingPrice ? Number(data.BtoBSellingPrice) : data.BtoBPrice ? Number(Object.assign(data.BtoBPrice, 0)) : undefined;
        this.model.BtoBDiscount = data.BtoBDiscount;
        this.model.Type = data.Type;
        this.model.OccasionId = data.OccasionId;
        this.model.FabricId = data.FabricId;
        this.model.WarehouseId = data.WarehouseId;
        this.model.BrandId = data.BrandId;
        this.model.LengthId = data.LengthId;
        this.model.ColorId = data.ColorId;
        this.model.PatternId = data.PatternId;
        this.model.UniqueId = data.UniqueId;
        this.model.Keyword = data.Keyword;
        this.model.MetaTitle = data.MetaTitle;
        this.model.MetaDesc = data.MetaDesc;
        this.ProductFiles = data.Files;
        this.model.Stocks = data.Stocks;
        this.model.ViewSectionId = data.ViewSectionId ? data.ViewSectionId : undefined;
        this.modelBk = Object.assign({}, this.model);
        //  this.getBrandDropDown(data.WarehouseId);
        //  this.getSubLookUpDropDown(data.CategoryId);

      } else {
        this.reset();
        this.toast.error(response.Message?.toString(), 'Error');
      }
    },
      error => {
      });
  }
  GetDropDown() {
    let serve = this._commonService.GetDropDown([DropDown_key.ddlCategory, DropDown_key.ddlCaptionTag,
    DropDown_key.ddlProductViewSection, DropDown_key.ddlProductSize, DropDown_key.ddlProductDiscount,
    DropDown_key.ddlProductOccasion, DropDown_key.ddlProductFabric, DropDown_key.ddlProductLength,
    DropDown_key.ddlProductColor, DropDown_key.ddlProductPattern, DropDown_key.ddlWarehouse,
    DropDown_key.ddlProductBrand, DropDown_key.ddlRecordType]).subscribe(res => {
      serve.unsubscribe();
      if (res.IsSuccess) {
        const ddls = res?.Data as DropDownModel;
        this.dropDown.ddlCaptionTag = ddls?.ddlCaptionTag;
        this.dropDown.ddlCategory = ddls?.ddlCategory;
        this.dropDown.ddlProductViewSection = ddls?.ddlProductViewSection;
        this.dropDown.ddlProductSize = ddls?.ddlProductSize;
        this.dropDown.ddlProductDiscount = ddls?.ddlProductDiscount;
        this.dropDown.ddlProductOccasion = ddls?.ddlProductOccasion;
        this.dropDown.ddlProductFabric = ddls?.ddlProductFabric;
        this.dropDown.ddlProductLength = ddls?.ddlProductLength;
        this.dropDown.ddlProductColor = ddls?.ddlProductColor;
        this.dropDown.ddlProductPattern = ddls?.ddlProductPattern;
        this.dropDown.ddlWarehouse = ddls?.ddlWarehouse;
        this.dropDown.ddlRecordType = ddls?.ddlRecordType;
        this.dropDown.ddlProductBrand == ddls?.ddlProductBrand;
        let warehouse = this.formgrp.get('Warehouse');
        this.model.WarehouseId = this.dropDown.ddlWarehouse[0].Value;
        this.getBrandDropDown(this.model.WarehouseId);
        if (this.dropDown?.ddlWarehouse?.length == 1) {
          this.getBrandDropDown(this.model.WarehouseId);

        } else if (this.dropDown.ddlWarehouse?.length > 1) {
          warehouse?.addValidators(Validators.required);
        }

        warehouse?.updateValueAndValidity();
        this.ddlProductSize();
      }
    });
  }
  getSubLookUpDropDown(value: string) {

    if (value?.length > 0) {
      const ddlModel = {} as FilterDropDownPostModel;
      ddlModel.FilterFromKey = this.ddlkeys.ddlLookup;
      ddlModel.Key = this.ddlkeys.ddlSublookup;
      ddlModel.Values = [value];
      this.dropDown.ddlSublookup = [];

      this._commonService.GetFilterDropDown(ddlModel)?.subscribe(x => {
        if (x.IsSuccess) {
          const ddls = x?.Data as DropDownModel;
          this.dropDown.ddlSublookup = Object.assign(ddls.ddlSublookup, []);
        }

        if (this.dropDown.ddlSublookup?.findIndex(x => x.Value == this.model.SubCategoryId) == -1) {
          this.model.SubCategoryId = undefined;
        }
        if (this.dropDown.ddlSublookup?.findIndex(x => x.Value == this.modelBk.SubCategoryId) >= 0) {
          this.model.SubCategoryId = this.modelBk.SubCategoryId;
        }
      });
    } else {
      this.dropDown.ddlSublookup = [];
    }
  }
  getBrandDropDown(value: string) {

    if (value?.length > 0) {
      const ddlModel = {} as FilterDropDownPostModel;
      ddlModel.FilterFromKey = this.ddlkeys.ddlWarehouse
      ddlModel.Key = this.ddlkeys.ddlProductBrand
      ddlModel.Values = [value]
      this._commonService.GetFilterDropDown(ddlModel).subscribe(x => {
        if (x.IsSuccess) {
          const ddls = x?.Data as DropDownModel;
          this.dropDown.ddlProductBrand = ddls.ddlProductBrand
        }
        if (this.dropDown.ddlProductBrand?.findIndex(x => x.Value == this.model.BrandId) == -1) {
          this.model.BrandId = undefined;
        }
        if (this.dropDown.ddlProductBrand?.findIndex(x => x.Value == this.modelBk.BrandId) >= 0) {
          this.model.BrandId = this.modelBk.BrandId;
        }

      });
    } else {
      // this.dropDown.ddlProductBrand = [];
    }
  }
  onDocumentAttach(file: FileInfo[]) {
    this.model.ImagePath = file[0].FileBase64;
    this.isFileAttached = true;
  }
  RemoveDocument(file: string) {
    this.model.ImagePath = '';
  }
  onProductFileAttach(file: FileInfo[]) {
    if (file?.length > 0) {
      if (!this.model.Files) {
        this.model.Files = [];
      }
      this.model.Files = file.map(x => { return x.FileBase64 });
      this.productFile = undefined
    } else {
      this.model.Files = [];
    }
  }
  getFileType(fileName: string) {
    const ext = fileName?.split('.')[fileName?.split('.')?.length - 1]?.toLowerCase() ?? '';
    if (['doc', 'docx', 'ppt', 'pptx', 'pdf', 'txt', 'xlx', 'xlsx'].some(x => x.toLowerCase() === ext)) {
      return 'doc';
    } else if (['jpeg', 'gif', 'png', 'jpg', 'svg', 'webp'].some(x => x.toLowerCase() === ext) || fileName.includes('drive.google.com') || (fileName.includes('jpg') || fileName.includes('dropbox.com'))) {
      return 'image';
    }
    else if (['mp4', 'mkv', 'avi',].some(x => x.toLowerCase() === ext)) {
      return 'video';
    } else {
      return ext;
    }

  }
  deleteProductFile(id: string) {
    this._commonService.Question(Message.DeleteConfirmation as string).then(result => {
      if (result) {
        let subscription = this._productService.DeleteProductFile(id).subscribe(
          data => {
            subscription.unsubscribe();
            if (data.IsSuccess) {
              this.toast.success(data.Message as string, "Delete");
              const idx = this.ProductFiles.findIndex(x => x.Id == id);
              this.ProductFiles.splice(idx, 1);
            }
          },
          error => {
            this._commonService.Error(error.message as string)

          }
        );
      }
    });
  }
  getDiscountValue(price = 0, sellingPrice = 0) {

    if (price == sellingPrice) {
      return 0;
    } else if (sellingPrice == 0) {
      return 100;
    }
    var ddValue = ((price - sellingPrice) / price) * 100;
    return Math.floor(ddValue);
  }


  onAddStock(isBtoB: boolean) {
    this.stockModel = {} as ProductStockModel;
    this.tempStock = undefined;
    this.stockModel.IsBtoB = isBtoB;
    if (isBtoB) {
      this.stockModel.UnitPrice = Number(this.model.BtoBPrice);
      this.stockModel.SellingPrice = Number(this.model.BtoBSellingPrice);
    } else {
      this.stockModel.UnitPrice = Number(this.model.Price);
      this.stockModel.SellingPrice = Number(this.model.SellingPrice);
    }


    this.ddlProductSize();
  }

  onEditStock(stockItem: ProductStockModel, idx: number) {
    this.ddlProductSize()
    if (stockItem && stockItem.Id != null) {
      idx = this.model.Stocks.findIndex(x => x.Id === stockItem.Id);
    }
    this.stockModel = Object.assign({}, this.model.Stocks[idx]);
    this.tempStock = Object.assign({}, this.stockModel);
    this.model.Stocks.splice(idx, 1);
  }
  onRemoveStock(idx: number) {
    this.model.Stocks.splice(idx, 1);
  }
  onCancelEdit() {
    if (this.tempStock) {
      this.model.Stocks.push(this.tempStock)
    }

  }
  onCloseClick() {
    this.OnClose.emit(true);
  }
  backToPrevious() {
    history.back();
  }

  onSaveStock() {
    if (this.model.Stocks && this.model.Stocks.some(x => x.IsBtoB == this.stockModel.IsBtoB && x.SizeId === this.stockModel.SizeId)) {
      this.stockModel.SizeId = undefined;
      this.toast.warning("Size Already exist, Please Select different size...", "Oops");
      return
    }
    this.stockFormGroup.markAllAsTouched();
    if (this.stockFormGroup.valid) {
      if (!this.model.Stocks) {
        this.model.Stocks = [];
      }
      this.model.Stocks.push(this.stockModel);
      this.stockModel = {} as ProductStockModel;
      this.tempStock = undefined;
      this.stockModelPopup.nativeElement.click();
    }
  }

  applyMainPrice() {
    this.stockModel.UnitPrice = this.stockModel.IsBtoB ? Number(this.model.BtoBPrice) : Number(this.model.Price);
  }
  applySellingPrice() {
    this.stockModel.SellingPrice = this.stockModel.IsBtoB ? Number(this.model.BtoBSellingPrice) : Number(this.model.SellingPrice);
  }

  onGetProductSizeLabel(sizeId: any) {
    return this.dropDown?.ddlProductSize?.find(x => x.Value === sizeId)?.Text ?? '';
  }
}


