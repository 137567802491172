<div class="content-box" *ngIf="model?.Id">

  <div class="row">
    <!-- <div class="single-detail-img" *ngIf="selectedCategory.IsShowThumbnail">
      <img [src]="model.ImagePath" ViewImage [defaultSrc]="'assets/images/images/client/gulmohar/no-img.png.png'" />
    </div> -->
    <div class="gen-details-info">
      <div class="gen-details-info-img" *ngIf="selectedCategory.IsShowThumbnail">
        <img [src]="model.ImagePath" ViewImage [defaultSrc]="'assets/images/client/gulmohar/gulmohar-default-landscape.jpg'" />
      </div>

      <h2> {{model.Title}}</h2>

      <ul class="gen-details-card">
        <li>
          <strong>Category: </strong>
          <small> {{model.Category}}</small>
        </li>
        <li>
          <strong>TypeName: </strong>
          <small> {{model.TypeName}} </small>
        </li>
        <li *ngIf="selectedCategory.IsShowUrl">
          <strong> Url: </strong>
          <a href="{{model.Url}}" target="_blank"><i class="fa-light fa-globe"></i></a>
        </li>
        <li *ngIf="selectedCategory.IsLookupType">
          <strong> {{selectedCategory.LookupType}} </strong>
          <small> {{model.Lookup}} </small>
        </li>
        <li *ngIf="selectedCategory.IsSubLookupType">
          <strong> sub {{selectedCategory.LookupType}}: </strong>
          <small>{{model.SubLookup}} </small>

        </li>
        <li>
          <strong>Status: </strong>
          <small> {{model.IsActive ?'Active':'In-active'}} </small>
        </li>
        <li>
          <strong>Sorted Order: </strong>
          <small> {{model.SortedOrder}} </small>
        </li>


      </ul>

      <div *ngIf="selectedCategory.IsShortDesc">
        <strong>
          Short Description
        </strong>
        <p [innerHTML]="model.ShortDescription">
        </p>

      </div>
      <div *ngIf="selectedCategory.IsFullDesc">
        <strong>
          Description
        </strong>
        <p [innerHTML]="model.Description">
        </p>
      </div>


      <div class="gen-entry-attachment-list" *ngIf="selectedCategory.IsFileUploader && model.Data!.length>0">
        <strong class="mb-2"> Files </strong>
        <ng-container *ngFor="let itm of model.Data">
          <div [ngSwitch]="getFileType(itm.Value)">

            <div *ngSwitchCase="'image'" href="javascript:void()" class="gen-entry-attachment-list-items">
              <img [src]="itm.Value" ViewImage [defaultSrc]="'assets/images/images/client/gulmohar/no-img.png.png'" />
            </div>
            <div *ngSwitchCase="'doc'" href="javascript:void()" class="gen-entry-attachment-list-items">
              <i class="bi bi-file-earmark-pdf"></i>
            </div>
            <div *ngSwitchCase="'video'" href="javascript:void()" class="gen-entry-attachment-list-items">
              <i class="fa-light fa-video"></i>
            </div>

          </div>
        </ng-container>
      </div>

      <div class="gen-entry-attachment-list"
        *ngIf="selectedCategory.IsOtherFileUploader && model.OtherData!.length>0">
        <strong class="mb-2"> Other Files </strong>
        <ng-container *ngFor="let itm of model.OtherData">
          <div [ngSwitch]="getFileType(itm.Value)">

            <div *ngSwitchCase="'image'" href="javascript:void()" class="gen-entry-attachment-list-items">
            <img [src]="itm.Value" ViewImage [defaultSrc]="'assets/images/images/client/gulmohar/no-img.png.png'" />
            </div>
            <div *ngSwitchCase="'doc'" href="javascript:void()"
              class="gen-entry-attachment-list-items">
            <i class="bi bi-file-earmark-pdf"></i>
            </div>
            <div *ngSwitchCase="'video'" href="javascript:void()"
              class="gen-entry-attachment-list-items">
               <i class="fa-light fa-video"></i> 
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>