import { Component, LOCALE_ID, OnInit } from '@angular/core';;
import { delay, switchMap, take } from 'rxjs/operators';
import { DashboardService, DashboardViewModel } from 'src/app/Shared/Services/dashboard.service';
import { registerLocaleData } from '@angular/common';
import localeHi from '@angular/common/locales/hi';
import { RecordTypeEnum } from 'src/app/Shared/Enum/fixed-value';
import { FinancialReportDTO, ReportService } from 'src/app/Shared/Services/Report/report.service';
import { OrderStatusEnum } from 'src/app/Shared/Helper/constants';
import { of } from 'rxjs';
import { CommonService } from 'src/app/Shared/Services/common.service';
registerLocaleData(localeHi);

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  providers: [{ provide: LOCALE_ID, useValue: 'hi-IN' }], // Set locale to Indian

  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  model!: DashboardViewModel;

  financialModel: FinancialReportDTO[] = [];
  dateValue: Date[] = [];
  recordTypeEnum = RecordTypeEnum;
  recordType: string = this.recordTypeEnum.All;
  totalAmount = 0;
  netAmount = 0;
  totalOrder = 0;

  constructor(private readonly _reportService: ReportService, private _commonService: CommonService,
    private _dashboardService: DashboardService) {
    this.bindMonthDate();

  }
  ngOnInit(): void {
    setTimeout(() => {

      this.getCount();
    }, 100);
  }

  getCount(): void {
    if (this.dateValue?.length == 0) {
      this.bindMonthDate();
    }

    const dt = {
      fromDate: this.dateValue[0],
      toDate: this.dateValue[1],
      type: this.recordType
    }

    this._dashboardService.GetCountDetail(dt).pipe(take(1)).subscribe(response => {

      if (response.IsSuccess) {
        this.model = response.Data as DashboardViewModel;

        this._dashboardService.dashboardCount = this.model;
      }
    });
  }
  GetFinancialRecord(): void {
    if (this.dateValue?.length == 0) {
      this.bindMonthDate();
    }
    let dt = {
      fromDate: this.dateValue[0],
      toDate: this.dateValue[1],
      type: this.recordType
    }
    this._reportService.GetFinancialRecord(dt).pipe(take(1)).subscribe(response => {
      if (response.IsSuccess) {
        this.financialModel = response.Data as FinancialReportDTO[];
        this.totalAmount = this.financialModel?.reduce((sum, item) => sum + item.TotalAmount, 0) ?? 0;
        this.netAmount = this.financialModel?.find(x => x.StatusId == OrderStatusEnum.Delivered)?.TotalAmount ?? 0;
        this.totalOrder = this.financialModel?.reduce((sum, item) => sum + item.TotalOrders, 0) ?? 0;
      }
    });
  }

  bindMonthDate() {
    const today = new Date(); // Current date
    const newDate = new Date(today); // Create a copy of the current date
    newDate.setDate(today.getDate() - 30); // Subtract n days
    this.dateValue = [newDate, today];
  }
}
