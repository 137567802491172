
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IndexModel } from 'src/app/Shared/Helper/common-model';
import { Message } from 'src/app/Shared/Helper/constants';
import { CommonService } from 'src/app/Shared/Services/common.service';
import { ProductMasterViewModel, ProductService } from '../../../Shared/Services/product.service';
import { take } from 'rxjs/operators';
import { RecordTypeEnum } from 'src/app/Shared/Enum/fixed-value';
import { FileInfo } from 'src/app/Shared/Helper/shared/file-selector/file-selector.component';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  model!: ProductMasterViewModel[];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  selectedRecord = "";
  ViewMode = "Detail"
  displayedColumns: string[] = ['index', 'SKU', 'Name', 'ImagePath', 'Category', 'SubCategory', 'Price', 'IsActive', 'Action'];
  ViewdisplayedColumns = [
    { Value: 'Name', Text: 'Name' },
    { Value: 'Category', Text: 'Category' },
    { Value: 'SubCategory', Text: 'Sub Category' },
    { Value: 'CaptionTag', Text: 'Caption Tag' }];
  indexModel = new IndexModel();
  totalRecords: number = 0;
  noRecordData = {
    subject: 'Can you please add your first product.',
    Description: "You haven't added product yet. please add your new products",
    url: null,
    urlLabel: 'Add New Product'
  };
  productTypeEnum = RecordTypeEnum;
  productType: string = this.productTypeEnum.All;
  showImportPopup: any = false;
  @ViewChild('btnClose') btnClose!: ElementRef;
  @ViewChild('AddBtnHdn') AddBtnHdn!: ElementRef;
  importFileURL!: string;
  constructor(private _router: Router, private _activatedRoute: ActivatedRoute, private readonly _commonService: CommonService,
    private readonly toast: ToastrService, private _productService: ProductService, private http: HttpClient) {
    _activatedRoute.params.subscribe(x => {
      this.getList();
    })
  }
  onAddClick() {
    this.AddBtnHdn.nativeElement.click();
  }

  ngOnInit(): void {
    this.getList();
  }
  geProductList(evt: any) {
    this.indexModel.Page = 1;
    this.productType = evt.currentTarget !=null ? evt?.currentTarget?.value : this.productTypeEnum.All;;

    this.getList();
  }
  getList(): void {
    this.indexModel.AdvanceSearchModel['Type'] = this.productType;
    this._productService.GetList(this.indexModel).pipe(take(1)).subscribe(response => {
      if (response.IsSuccess) {
        this.model = response.Data as ProductMasterViewModel[];
        this.dataSource = new MatTableDataSource<ProductMasterViewModel>(this.model);
        this.totalRecords = (Number(response.TotalRecord) > 0 ? response.TotalRecord : 0) as number;
        if (!this.indexModel.IsPostBack) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      } else {
        // Toast message if  return false ;
        this.toast.error(response.Message?.toString(), 'Error');
      }
    },
      error => {
      });
  }

  onImport(file: FileInfo) {

    this._productService.ImportProductExcel(file).subscribe(res => {
      if (res.IsSuccess) {
        this.toast.success(res.Message as string, 'Import');
        this.showImportPopup = false;
      } else if (!res.IsSuccess && res.StatusCode == 200) {
        this._commonService.Error(`<pre>${res.Data}</pre>`, res.Message as string)
      } else {
        this._commonService.Error(res.Message as string, 'Oops')

      }
    });
  }
  sortData(event: any): void {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == "asc" ? true : false;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onSearch() {
    this.indexModel.Page = 1;
    this.getList();
  }

  onPaginateChange(event: any) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  OnActiveStatus(Id: string) {
    this._commonService.Question(Message.ConfirmUpdate as string).then(isTrue => {
      if (isTrue) {
        let subscription = this._productService.ChangeProductMasterActiveStatus(Id).subscribe(
          data => {
            subscription.unsubscribe();
            if (data.IsSuccess) {
              this.toast.success(data.Message as string, 'Remove');
              const idx = this.model.findIndex(x => x.Id == Id);
              this.model[idx].IsActive = !this.model[idx].IsActive;
              this.dataSource = new MatTableDataSource<ProductMasterViewModel>(this.model);
            } else {
              this.toast.warning(data.Message as string, 'Server Error');
            }
          },
          error => {
            this.toast.error(error.Message as string, 'Error');
          }
        );
      }
    });

  }

  updateDeleteStatus(id: string) {

    this._commonService.Question(Message.ConfirmUpdate as string).then(result => {
      if (result) {
        let subscription = this._productService.DeleteProductMaster(id).subscribe(
          data => {
            subscription.unsubscribe();
            if (data.IsSuccess) {
              this._commonService.Success(data.Message as string)
              const idx = this.model.findIndex(x => x.Id == id);
              this.model.splice(idx, 1);
              this.totalRecords--;
              this.dataSource = new MatTableDataSource<ProductMasterViewModel>(this.model);
            }
          },
          error => {
            this._commonService.Error(error.message as string)

          }
        );
      }
    });
  }

  onClear() {
    this.indexModel.Search = '';
    this.indexModel.Page = 1;
    this.getList();
  }

  changeViewMode() {
    this.ViewMode = (this.ViewMode == 'Edit' ? 'Detail' : 'Edit');
  }

  isDataRefresh: boolean = false;
  reloadData(value: { status: boolean, recordId: string }) {
    this.isDataRefresh = value.status;
    if (value.status) {
      this.getList();
      setTimeout(() => {
        this.selectedRecord = value.recordId
        this.isDataRefresh = !value.status;
        this.ViewMode = 'Detail';
      }, 15);

    }
  }

  closePopup() {
    this.btnClose.nativeElement.click();
  }


  downloadExcel(): void {
    const parsedData = this.model.map(r => ({

      Name: r.Name,
      ImagePath: r.ImagePath,

      Price: r.Price,
      ['Selling Price']: r.SellingPrice,
      BtoBPrice: r.BtoBPrice,
      ['BtoB Selling Price']: r.BtoBSellingPrice,
      ['BtoB Discount']: r.BtoBDiscount,
      ['Type']: r.TypeName,
      CaptionTag: r.CaptionTag,
      Category: r.Category,
      SubCategory: r.SubCategory,
      Discount: r.Discount,
      Occasion: r.Occasion,
      Fabric: r.Fabric,
      Length: r.Length,
      Color: r.Color,
      Pattern: r.Pattern,
      Keyword: r.Keyword,
      MetaTitle: r.MetaTitle,
      MetaDesc: r.MetaDesc,
      ViewSection: r.ViewSection,
      Brand: r.Brand,
      Desc: r.Desc,
    }));
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(parsedData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'data');
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = `${fileName}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  downloadTemplate() {
    const download = (url: string) => {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (this.importFileURL) {
      download(this.importFileURL);
    } else {
      this._productService.getImportFile().subscribe(res => {
        if (res.IsSuccess) {
          this.importFileURL = res.Data as string
          download(this.importFileURL);
        }

      });
    }

  }
}
