<!-- Page Header Start-->
<!-- <div class="page-header">
  <div class="page-header-title">
    General Entry Category Detail
  </div>
  <div class="page-header-action">
    <button *ngIf="!this.model?.IsSystemEntry" routerLink="../../edit/{{recordId}}" class="page-header-action-items"
      data-title="Edit"> <i class="fa-light fa-pen"></i></button>
    <button routerLink="/admin/master/general-entry-category" class="page-header-action-items"
      data-title="Back to list"> <i class="fa-light fa-arrow-left"></i> </button>
  </div>
</div> -->
<!-- Page Header End-->


<div class="content-box">

  <div class="row">
      <div class="single-detail-img">
        <img [src]="this.model.ImagePath" ViewImage [defaultSrc]="'assets/images/images/client/gulmohar/no-img.png.png'">
      </div>
      <div class="gen-details-info">
        <h2>{{this.model.Name}}</h2>
        <ul class="gen-details-card">
          <li>
            <strong>Sort Order: </strong>
            <small> {{this.model.SortedOrder}} </small>
          </li>
          <li>
            <strong>Content Type : </strong>
            <small> {{this.model.ContentTypeText}} </small>
          </li>
          <li>
            <strong> Is Show In Main: </strong>
            <small> {{this.model.IsShowInMain?'Yes':'No'}} </small>
          </li>
          <li>
            <strong> Is Show Data In Main: </strong>
            <small> {{this.model.IsShowDataInMain?'Yes':'No'}} </small>
          </li>
          <li>
            <strong> Is Single Entry: </strong>
            <small> {{this.model.IsSingleEntry? 'Yes': 'No'}} </small>
        
          </li>
          <li>
            <strong> Is System Entry: </strong>
            <small> {{this.model.IsSystemEntry? 'Yes': 'No'}} </small>
          </li>
          <li>
            <strong>Show Thumbnail: </strong>
            <small> {{this.model.IsShowThumbnail? 'Yes': 'No'}} </small>
          </li>
          <li>
            <strong>Show URL Field: </strong>
            <small> {{this.model.IsShowUrl? 'Yes': 'No'}} </small>
          </li>
        
          <li>
            <strong>Show Summary: </strong>
            <small> {{this.model.IsShortDesc? 'Yes': 'No'}}</small>
          </li>
        
          <li>
            <strong>Show Description: </strong>
            <small> {{this.model.IsFullDesc? 'Yes': 'No'}}</small>
          </li>
          <li>
            <strong>Show File Uploader</strong>
        
            <small> {{this.model.IsFileUploader}}</small>
          </li>
          <li>
            <strong>Show Other FileUploader</strong>
        
            <small> {{this.model.IsOtherFileUploader}}</small>
          </li>
          <li>
            <strong>Show Lookup Type</strong>
        
            <small> {{this.model.IsLookupType}}</small>
          </li>
          <li>
            <strong>Lookup Type</strong>
            <small> {{this.model.LookupType}}</small>
          </li>
          <li>
            <strong>Show SubLookup Type</strong>
        
            <small>{{this.model.IsSubLookupType}}</small>
          </li>
          <li>
            <strong>Show Keyword</strong>
        
            <small> {{this.model.IsKeyword}}</small>
          </li>
        </ul>
      </div>
  </div>
</div>
