import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Shared/Helper/auth.service';
import { environment } from 'src/environments/environment';
;
@Component({
  selector: 'app-dashboard',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  @ViewChild('pageBody', { static: false }) appPageBody!: ElementRef;

  constructor(private renderer: Renderer2, private readonly _route: Router, readonly _authService: AuthService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    setTimeout(() => {

      let theme: string = localStorage.getItem('currentTheme') != null ? localStorage.getItem('currentTheme') as string : 'theme-default' as string;
      this.setTheme(theme);
    }, 100);
  }
  setTheme(cssClass: string) {
    if (cssClass == '') {
      var data =
        localStorage.getItem('currentTheme') != null ? localStorage.getItem('currentTheme') : 'theme-default';
      if (data) {
        cssClass = data;
      }
    } else {
      localStorage.setItem('currentTheme', cssClass);
    }

    const classes = this.document.body.classList;
    while (classes.length>0) {
      this.document.body.classList.remove(classes[classes.length-1])
    }

    this.document.body.classList.add(environment.ThemeName);
    if (cssClass != undefined) {
      this.document.body.classList.add(cssClass);
    }

  }

}
