<!-- Page Header Start-->
<!-- <div class="page-header">
  <div class="page-header-title">
    {{pageName | titlecase}}
  </div>
  <div class="page-header-action">
    <button routerLink="{{model.Id.length> 0 ?'../../' :'../'}}" class="page-header-action-items"
      data-title="Back to list"> <i class="fa-light fa-arrow-left"></i> </button>
  </div>
</div> -->
<!-- Page Header End-->

<div class="content-box">
  <form [formGroup]="formgrp" (ngSubmit)="onSubmit()">
    <div class="row">
      <!-- Done -->
      <div class="col-md-6 col-lg-4 mb-3">
        <label>Name</label>
        <input type="text" class="form-control" [(ngModel)]="model.Name" formControlName="Name"
          [ngClass]="{ 'is-invalid': f.Name.errors && f.Name.touched}" placeholder="Name">
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Name')?.hasError('required') && formgrp.get('Name')?.touched)">
          Please enter <strong>Name</strong>...!</div>
      </div>

      <div class="col-md-6 col-lg-4 mb-3">
        <label>Content Type</label>
        <ng-select [items]="this.dropDown.ddlContentType" formControlName="ContentType" [searchable]="true"
          bindLabel="Text" bindValue="Value" placeholder="Content Type"
          [ngClass]="{ 'is-invalid': f.ContentType.errors && f.ContentType.touched}" [(ngModel)]="model.ContentType">
        </ng-select>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('ContentType')?.hasError('required') && formgrp.get('ContentType')?.touched)">
          Please select <strong>Content Type</strong>...!</div>

      </div>

      <div class="col-md-6 col-lg-4 mb-3">
        <label> Image <span class="info-tooltip"> <i class="fa-regular fa-circle-info"></i>
            <div class="info-tooltip-hover"> Preferred size : 500*500 </div>
          </span></label>
        <input type="hidden" name="ImagePath" [(ngModel)]="model.ImagePath" formControlName="ImagePath">
        <app-file-selector [MaxFileLength]="1" (FilesChanged)="onDocumentAttach($event)"
          [CurrentFileLength]="model!.ImagePath ? 1: 0"
          [ngClass]="{ 'is-invalid': f.ImagePath.errors && f.ImagePath.touched}">
        </app-file-selector>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('ImagePath')?.hasError('required') && formgrp.get('ImagePath')?.touched)">
          Please upload image...!</div>

        <span *ngIf="model.ImagePath && !isFileAttached  && model.Id.length>0">

          <a href="javascript:void()" class="small-media-items position-relative">
            <div class="small-media-items-img"> <img [src]="model.ImagePath" ViewImage
                [defaultSrc]="'assets/images/images/client/gulmohar/no-img.png.png'" /> </div>
            <div class="small-media-items-action">
              <a href="javascript:void()" (click)="RemoveDocument(model.ImagePath)" title="Delete"><i
                  class="fa-light fa-trash-can"></i></a>
              <a [href]="model.ImagePath" target="_blank">
                <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
              </a>
            </div>
          </a>

        </span>

      </div>

      <div class="col-md-6 col-lg-4 mb-3">
        <!-- <label>Sort Order</label> -->

        <input type="number" [(ngModel)]="model.SortedOrder" (keypress)="_commonService.NumberOnly($event)"
          [minlength]="1" [maxLength]="6" formControlName="SortedOrder"
          [ngClass]="{ 'is-invalid': f.SortedOrder.errors && f.SortedOrder.touched}" class="form-control" id="input4"
          placeholder="Sort Order">
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('SortedOrder')?.hasError('required') && formgrp.get('SortedOrder')?.touched)">
          Enter Sort Order </div>
        <div class="invalid-feedback"
          *ngIf="!formgrp.get('SortedOrder')?.hasError('required') && ((formgrp.get('SortedOrder')?.hasError('maxlength') || formgrp.get('SortedOrder')?.hasError('minlength')) && formgrp.get('SortedOrder')?.touched) || (formgrp.get('SortedOrder')?.hasError('maxlength') || formgrp.get('SortedOrder')?.hasError('minlength'))">
          Sort Order length must be 1-6 digit. </div>


      </div>

      <div class="col-md-6 col-lg-4 mb-3">

        <div class="form-check checkbox-card">
          <input class="form-check-input" type="checkbox" [(ngModel)]="model.IsShowThumbnail"
            formControlName="IsShowThumbnail" id="IsShowThumbnail">
          <label class="form-check-label" for="IsShowThumbnail">
            Show Thumbnail
          </label>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 mb-3">

        <div class="form-check checkbox-card">
          <input class="form-check-input" type="checkbox" [(ngModel)]="model.IsShowUrl" formControlName="IsShowUrl"
            id="IsShowUrl">
          <label class="form-check-label" for="IsShowUrl">
            Show Url Field
          </label>
        </div>
      </div>


      <div class="col-md-6 col-lg-4 mb-3">

        <div class="form-check checkbox-card">
          <input class="form-check-input" type="checkbox" [(ngModel)]="model.IsShowInMain"
            formControlName="IsShowInMain" id="IsShowInMain">
          <label class="form-check-label" for="IsShowInMain">
            Show Category In Home screen
          </label>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 mb-3">

        <div class="form-check checkbox-card">
          <input class="form-check-input" type="checkbox" [(ngModel)]="model.IsShowDataInMain"
            formControlName="IsShowDataInMain" id="IsShowDataInMain">
          <label class="form-check-label" for="IsShowDataInMain">
            Show Data In Home Screen
          </label>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 mb-3">

        <div class="form-check checkbox-card">
          <input class="form-check-input" type="checkbox" [(ngModel)]="model.IsSingleEntry"
            formControlName="IsSingleEntry" id="IsSingleEntry">
          <label class="form-check-label" for="IsSingleEntry">
            Allow Single Entry
          </label>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 mb-3">

        <div class="form-check checkbox-card">
          <input class="form-check-input" type="checkbox" [(ngModel)]="model.IsShortDesc"
            formControlName="IsShortDesc" id="IsShortDesc">
          <label class="form-check-label" for="IsShortDesc">
            Show Short Desc
          </label>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 mb-3">
        <div class="form-check checkbox-card">
          <input class="form-check-input" type="checkbox" [(ngModel)]="model.IsFullDesc"
            formControlName="IsFullDesc" id="IsFullDesc">
          <label class="form-check-label" for="IsFullDesc">
            Show Full Desc
          </label>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 mb-3">
        <div class="form-check checkbox-card">
          <input class="form-check-input" type="checkbox" [(ngModel)]="model.IsFileUploader"
            formControlName="IsFileUploader" id="IsFileUploader">
          <label class="form-check-label" for="IsFileUploader">
            Show File Uploader
          </label>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 mb-3">
        <div class="form-check checkbox-card">
          <input class="form-check-input" type="checkbox" [(ngModel)]="model.IsOtherFileUploader"
            formControlName="IsOtherFileUploader" id="IsOtherFileUploader">
          <label class="form-check-label" for="IsOtherFileUploader">
            Show Other File Uploader
          </label>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 mb-3">
        <div class="form-check checkbox-card">
          <input class="form-check-input" (change)="updateMasterTypeValidation()" type="checkbox"
            [(ngModel)]="model.IsLookupType" formControlName="IsLookupType" id="IsLookupType">
          <label class="form-check-label" for="IsLookupType">
            show Master dropdown
          </label>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 mb-3" *ngIf="model.IsLookupType">
        <label>Master Type</label>
        <ng-select [items]="this.dropDown.ddlLookupTypeMaster" formControlName="LookupTypeId" [searchable]="true"
          bindLabel="Text" bindValue="Value" placeholder="Master Type"
          [ngClass]="{ 'is-invalid': f.LookupTypeId.errors && f.LookupTypeId.touched}" [(ngModel)]="model.LookupTypeId">
        </ng-select>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('LookupTypeId')?.hasError('required') && formgrp.get('LookupTypeId')?.touched)">
          Please select <strong>Master Type</strong>...!</div>

      </div>

      <div class="col-md-6 col-lg-4 mb-3" *ngIf="model.IsLookupType">
        <div class="form-check checkbox-card">
          <input class="form-check-input" type="checkbox" [(ngModel)]="model.IsSubLookupType"
            formControlName="IsSubLookupType" id="IsSubLookupType">
          <label class="form-check-label" for="IsSubLookupType">
            show Product filter sub dropdown
          </label>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 mb-3">
        <div class="form-check checkbox-card">
          <input class="form-check-input" type="checkbox" [(ngModel)]="model.IsKeyword" formControlName="IsKeyword"
            id="IsKeyword">
          <label class="form-check-label" for="IsKeyword">
            Show Keyword
          </label>
        </div>
      </div>

    </div>

    <div class="offcanvas-footer-fix">
      <button type="submit" class="btn btn-default me-3"> <i class="fas fa-check me-1"></i>
        {{model.Id.length> 0 ? 'Save ' : 'Add '}}</button>
      <button type="button" class="btn btn-default btn-border"> <i class="fas fa-times me-1"></i> Cancel</button>
    </div>
  </form>
</div>