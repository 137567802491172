import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IndexModel } from 'src/app/Shared/Helper/common-model';
import { Message } from 'src/app/Shared/Helper/constants';
import { CommonService } from 'src/app/Shared/Services/common.service';
import { GeneralEntryCategoryViewModel, GeneralEntryService, GeneralEntryViewModel } from '../../../../Shared/Services/Master/general-entry.service';
import { RecordTypeEnum } from 'src/app/Shared/Enum/fixed-value';

@Component({
  selector: 'app-general-entry-master',
  templateUrl: './general-entry-master.component.html',
  styleUrls: ['./general-entry-master.component.scss']
})
export class GeneralEntryMasterComponent implements OnInit {
  model!: GeneralEntryViewModel[];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  category = "";
  page = "Data entry"
  displayedColumns: string[] = ['index', 'Title', 'ImagePath', 'ShortDescription', 'IsActive', 'Action'];
  ViewdisplayedColumns = [{ Value: 'Title', Text: 'Title' },
  { Value: 'ShortDescription', Text: 'Short Description' }];
  indexModel = new IndexModel();
  totalRecords: number = 0;
  noRecordData = {
    subject: `Can you please add your first ${this.page.replace('~', ' ')}.`,
    Description: `You haven't added ${this.page.replace('~', ' ')} yet. please add your new ${this.page.replace('~', ' ')}`,
    url: undefined,
    urlLabel: `Add New ${this.page.replace('~', ' ')}`
  };
  selectedRecord = "";
  ViewMode = "Detail"
  typeData = {} as GeneralEntryCategoryViewModel;
  isShowForm = false;
  @ViewChild('btnClose') btnClose!: ElementRef<any>;
  @ViewChild('addNewRecord') addNewRecord!: ElementRef<any>;
  recordTypeEnum = RecordTypeEnum;
  recordType: string = this.recordTypeEnum.All;
  constructor(private readonly _activatedRoute: ActivatedRoute,
    private readonly _commonService: CommonService,
    private readonly toast: ToastrService, private _generalEntryService: GeneralEntryService) {

  }

  ngOnInit(): void {

    this._activatedRoute.params.subscribe(x => {
      this.page = this._activatedRoute.snapshot.params.category ? this._activatedRoute.snapshot.params.category : '';
      this.category = this._activatedRoute.snapshot.params.id ? this._activatedRoute.snapshot.params.id : '';
      this.getTypeData();
      this.noRecordData = {
        subject: `Can you please add your first ${this.page.replace('~', ' ')} record.`,
        Description: `You haven't added ${this.page.replace('~', ' ')} yet. please add your new ${this.page.replace('~', ' ')}`,
        url: undefined,
        urlLabel: `Add New ${this.page.replace('~', ' ')} record`
      };
      this.getList();
    })

  }
  GetListData(evt:any) {
    
    this.indexModel.Page = 1;
    this.recordType = evt.currentTarget !=null ? evt?.currentTarget?.value : this.recordTypeEnum.All;
    this.getList();
  }
  getList(): void {
    this.indexModel.AdvanceSearchModel['Type'] = this.recordType;
    this.indexModel.AdvanceSearchModel["category"] = this.category;
    // this.indexModel.
    this._generalEntryService.GetListGeneralEntry(this.indexModel).subscribe(response => {
      if (response.IsSuccess) {
        this.model = response.Data as GeneralEntryViewModel[];
        this.dataSource = new MatTableDataSource<GeneralEntryViewModel>(this.model);
        this.totalRecords = (Number(response.TotalRecord) > 0 ? response.TotalRecord : 0) as number;
        if (!this.indexModel.IsPostBack) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      } else {
        this.toast.error(response.Message?.toString(), 'Error');
      }
    },
      error => {
        this.toast.error(error.Message as string, 'Error');
      });
  }

  sortData(event: any): void {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == "asc" ? true : false;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onSearch() {
    this.indexModel.Page = 1;
    this.getList();
  }

  onPaginateChange(event: any) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  OnActiveStatus(Id: string) {
    this._commonService.Question(Message.ConfirmUpdate as string).then(isTrue => {
      if (isTrue) {
        let subscription = this._generalEntryService.ChangeGeneralEntryActiveStatus(Id).subscribe(
          data => {
            subscription.unsubscribe();
            if (data.IsSuccess) {
              this.toast.success(data.Message as string, 'Remove');
              const idx = this.model.findIndex(x => x.Id == Id);
              this.model[idx].IsActive = !this.model[idx].IsActive;
              this.dataSource = new MatTableDataSource<GeneralEntryViewModel>(this.model);
            } else {
              this.toast.warning(data.Message as string, 'Server Error');
            }
          },
          error => {
            this.toast.error(error.Message as string, 'Error');
          }
        );
      }
    });

  }

  updateDeleteStatus(id: string) {
    this._commonService.Question(Message.ConfirmUpdate as string).then(result => {
      if (result) {
        const subscription = this._generalEntryService.DeleteGeneralEntry(id).subscribe(
          data => {
            subscription.unsubscribe();
            if (data.IsSuccess) {
              this._commonService.Success(data.Message as string)
              const idx = this.model.findIndex(x => x.Id == id);
              this.model.splice(idx, 1);
              this.totalRecords--;
              this.dataSource = new MatTableDataSource<GeneralEntryViewModel>(this.model);
            }
          },
          error => {
            this._commonService.Error(error.message as string);
          }
        );
      }
    });
  }
  onClear() {
    this.indexModel.Search = '';
    this.indexModel.Page = 1;
    this.getList();
  }
  addRecord() {
    this.selectedRecord = '';
    this.ViewMode = 'Add';
    this.isShowForm = true;
    this.addNewRecord.nativeElement.click();
  }
  changeViewMode() {
    this.ViewMode = (this.ViewMode == 'Edit' ? 'Detail' : 'Edit');
  }
  isDataRefresh: boolean = false;
  reloadData(value: { status: boolean, recordId: string }) {
    this.isDataRefresh = value.status;
    if (value.status) {
      this.getList();
      setTimeout(() => {
        this.selectedRecord = value.recordId
        this.isDataRefresh = !value.status;
        // this.ViewMode = 'Detail'; //temp comment
        this.closePopup();
      }, 15);

    }
  }

  closePopup() {
    this.isShowForm = true;
    this.btnClose.nativeElement.click();
  }
  getTypeData() {
    this._generalEntryService.GetGeneralEntryCategory(this.category).subscribe(response => {
      if (response.IsSuccess) {
        this.typeData = response.Data as GeneralEntryCategoryViewModel;
      } else {
        this.toast.error(response.Message?.toString(), 'Error');
      }
    });
  }
}
