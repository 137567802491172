import { Component, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './Shared/Helper/auth.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isAuth: boolean = false;
  constructor(public _authService: AuthService, private renderer: Renderer2, private readonly _route: Router,@Inject(DOCUMENT) private document: Document) {
    this._authService.IsAuthenticate();
    this._authService.IsAuthentication.subscribe(x => {
      this.isAuth = x;
    });
    this.loadTheme(environment.ThemeName);
  }

  loadTheme(theme: string): void {
    const themeLinkId = 'app-theme';
    let themeLink = document.getElementById(themeLinkId) as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = `assets/css/clients/${theme}.css`;
    } else {
      themeLink = document.createElement('link');
      themeLink.id = themeLinkId;
      themeLink.rel = 'stylesheet';
      themeLink.href = `assets/css/clients/${theme}.css`;
      document.head.appendChild(themeLink);
    }

    this.renderer.addClass(this.document.body, `${theme}`);

  }

}
